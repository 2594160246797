function checkCookies(){
    let cookieDate = localStorage.getItem('cookieDate');
    // let cookieNotification = document.getElementById('cookie_notification');
    // let cookieBtn = cookieNotification.querySelector('.cookie_accept');

    // Если записи про кукисы нет или она просрочена на 1 год, то показываем информацию про кукисы // 31536000000
    if( !cookieDate || (+cookieDate + 31536000000) < Date.now() ){
        const footer = document.querySelector('footer')
        footer.insertAdjacentHTML('afterend', '<div id="cookie_notification"><p>Мы используем cookie, чтобы сайтом было пользоваться удобно.</p><button class="cookie_accept">Хорошо</button></div>')
        
        let cookieNotification = document.getElementById('cookie_notification')
        let cookieBtn = cookieNotification.querySelector('.cookie_accept')

        setTimeout(() => {
            cookieNotification.classList.add('show')
        }, 3000)

        cookieBtn.addEventListener('click', function(){
            localStorage.setItem( 'cookieDate', Date.now() )
            cookieNotification.classList.remove('show')
            setTimeout(() => cookieNotification.remove(), 300)
        })
    }
    
    // При клике на кнопку, в локальное хранилище записывается текущая дата в системе UNIX
    // cookieBtn.addEventListener('click', function(){
    //     localStorage.setItem( 'cookieDate', Date.now() );
    //     cookieNotification.classList.remove('show');
    // })

}
checkCookies();