const blockNav = document.querySelector('.block_nav')
const heightBlockWhite = document.querySelector('.white').clientHeight
const heightBlockItem = document.querySelector('.slider-nav__item').clientHeight
const marginTopSlide = (heightBlockWhite - heightBlockItem) / 2

blockNav.style.marginTop = `${marginTopSlide}px`

// console.log('высота блока white = ' + heightBlockWhite);
// console.log('высота блока слайда = ' + heightBlockItem);
// console.log('отступ сверху = ' + marginTopSlide);

$(document).ready(function(){
    $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav'
    });

    $('.slider-nav').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: false,
        focusOnSelect: true
      });

      $('.slider_mac').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.slider_mac_info'
      })

      $('.slider_mac_info').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.slider_mac',
        dots: false,
        arrows: false
      })
      $('.mob_slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false
      })
})